<template>
  <div class="w-screen h-screen bg-primary-100">
    <Header
      @showBillingModal="showBillingModal"
      :billing="billing"
      :emailVerified="$parent.emailVerified"
      :user="$parent.user"
      @helpSelected="helpSelected"
      @profileSelected="profileSelected"
      :hideTranscribe="true"
    />

    <div
      class="flex flex-col items-center justify-center" style="margin-top:35vh;"
    >
      <BrandIcon class="h-11 text-red w-11"/>

      <div
        class="mt-2 text-sm leading-6 text-center text-primary"
        v-html="statuses[currentState]"
      />

      <Button
        @click="goToDrive"
        classname="mt-4 pr-2.75"
      >
        Go To Spext Drive
      </Button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/services/store'
import CONSTANTS from '@/constants/CONSTANTS'
import * as firebase from 'firebase/app'
import 'firebase/database'
import axios from 'axios'
import BrandIcon from '@/components/base/icons/Brand.vue'
import Button from '@/components/base/buttons/Button.vue'

require('@/assets/css/jquery-confirm.min.css')
require('@/assets/js/jquery-confirm.min.js')
require('@fortawesome/fontawesome-pro/css/all.css')

export default {
  name: 'teamInvite',
  components: {
    BrandIcon,
    Button,
    Header: () => import(/* webpackChunkName: "DriveHeader" */'./Drive/core/Header')
  },
  data () {
    return {
      statuses: {
        0: 'Loading...',
        200: 'Hurray! 🙂 <br>You are part of the team now.',
        201: 'Already invited!',
        202: 'Sorry, you are already on a plan',
        500: 'Sorry, incorrect invite',
        501: 'Unable to accept invite. Contact Support',
        502: 'Unable to connect to Server. Contact Support',
        503: `You can't invite yourself!`,
        504: `Seems you are logged in from some other account.<br>Please sign in from the email you received invite on.`
      },
      currentState: 0,
      result: '',
      members: {}
    }
  },
  mounted () {
    var vm = this
    if (vm.$parent.user.uid === vm.$route.query.adminid) {
      vm.currentState = 503
      delete localStorage['invitedEmail']
    } else if (vm.$parent.user.email !== vm.$route.query.invitedEmail) {
      vm.currentState = 504
      delete localStorage['invitedEmail']
    } else {
      firebase.database().ref(vm.$parent.user.uid + '/userInfo/member').once('value').then(function (res) {
        vm.members = res.val()
        for (var i in vm.members) {
          console.log(i, vm.members[i], vm.$route.query.adminid)
          if (vm.members[i] === vm.$route.query.adminid) {
            vm.currentState = 201
            delete localStorage['invitedEmail']
            console.log('breaking')
            break
          }
        }
        if (vm.currentState === 0) {
          try {
            axios.get(CONSTANTS.GET_USER + '?userId=' + vm.$route.query.adminid).then(function (res) {
              console.log('value is', res)
              if (Object.keys(res.data).length === 0) {
                console.log('User does not exist')
                vm.currentState = 500
                delete localStorage['invitedEmail']
              } else {
                firebase.database().ref(vm.$parent.user.uid + '/userInfo/member').push().set(res.data.uid).then(function () {
                  vm.currentState = 200
                  delete localStorage['invitedEmail']
                }).catch(function (err) {
                  console.log(err)
                  vm.currentState = 501
                  delete localStorage['invitedEmail']
                })
              }
            }).catch(function (err) {
              console.log('err', err)
              vm.currentState = 502
              delete localStorage['invitedEmail']
            })
          } catch (err) {
            console.log(err)
            vm.currentState = 502
            delete localStorage['invitedEmail']
          }
        }
      }).catch(function (err) {
        console.log(err)
        vm.currentState = 502
        delete localStorage['invitedEmail']
      })
    }
  },
  computed: {
    billing () {
      return store.state.app.userInfo.billing
    },
    uploadingList () {
      return store.state.app.uploadingList
    }
  },
  methods: {
    goToDrive () {
      this.$router.replace({ 'name': 'drive' })
    },
    ...mapActions({
      openModal: 'dialogs/openModal'
    }),
    openKnowPurposePopover () {
      this.openModal({ name: 'KnowPurpose' })
    },
    profileSelected (choice) {
      if (choice.title === 'Profile') {
        this.openModal({ name: 'AccountInfo' })
      } else if (choice.title === 'Admin Control') {
        this.openModal({ name: 'AdminControl' })
      } else if (choice.title === 'Logout') {
        this.logOut()
      }
    },
    helpSelected (choice) {
      // console.log('choice', choice)
      switch (choice) {
        case 'faq':
          this.showFAQ()
          break
        case 'tutorialVideos':
          this.showTutorialVideos()
          break
        case 'firstPreference':
          this.openKnowPurposePopover()
      }
    },
    showTutorialVideos () {
      window.analytics.track('sidebar_tutorialClicked')
      window.open('http://help.spext.co/en/collections/1872349-step-by-step-video-tutorials', '_blank')
    },
    showFAQ () {
      window.analytics.track('sidebar_faqClicked')
      window.open('http://help.spext.co/faqs', '_blank')
    },
    pauseAll () {
      var vm = this
      for (var i = 0; i < vm.$refs.drivePlayer.length; i++) {
        vm.$refs.drivePlayer[i].player.pause()
      }
    },
    showBillingModal (tab) {
      this.openModal({ name: 'AccountInfo', props: { tab } })
    },
    strip (str) {
      return str.replace(/^\s+|\s+$/g, '').toLowerCase()
    },
    podcastClicked (podcastKey, audio, audioMp3, title) {
      console.log('podcastClicked')
      this.$emit('podcastClicked', podcastKey, audio, audioMp3, title)
    },
    // before logout as user about upload progress etc.
    logOut () {
      var vm = this
      if (Object.keys(vm.uploadingList).length > 0) {
        $.confirm({
          title: 'Cancel Upload?',
          content: 'Your upload is not complete. Would you like to cancel the upload?',
          useBootstrap: false,
          theme: 'modern',
          escapeKey: 'cancel',
          buttons: {
            confirm: {
              text: 'CONTINUE UPLOAD',
              btnClass: 'btn-blue',
              action: function () {
                console.log('do nothing')
              }
            },
            cancel: {
              text: 'CANCEL UPLOAD',
              btnClass: 'btn-white',
              action: function () {
                vm.finalLogout()
              }
            }
          }
        })
      } else {
        vm.finalLogout()
      }
    },
    finalLogout () {
      store.dispatch('app/logout')
    }
  }
}
</script>
